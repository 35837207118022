.work-box {
    height: 170px;
    width: 100%;
    background-color: #fff;
    border-top-right-radius: 50px;
    border: 1.5px solid #5e5d5d;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.work-box:hover {
    border-color: #30256d;
    border-width: 1.5px;
}

.work-box-img {
    height: 220px;
    width: 220px;
    margin-top: 50px;
    filter: grayscale(100%);
    transition: filter 0.3s ease;
}

.work-box:hover .work-box-img {
    filter: grayscale(0%);
}

.guest-text {
    font-size: 2.5rem;
    font-weight: bold;
    background: linear-gradient(90deg, #000, #30256d, #30256d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.partnership-text {
    font-size: 2em;
    font-weight: 600;
    font-family: "Josefin Sans", sans-serif;
    background: linear-gradient(to right, #270054 10%, #8688c2 50%, #30256d 60%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 1.5s linear infinite;
    display: inline-block;
}

@media only screen and (max-width: 625px) {
    .guest-text {
        font-size: 1.8em;
    }

    .partnership-text {
        font-size: 1.5em;
        margin-top: 20px;
        text-align: center;
    }
}