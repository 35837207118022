.whatsapp-container {
    position: fixed;
    right: 10px;
    bottom: 70px;
    /* z-index: 1000; */
    visibility: hidden; /* Hide the icon by default */
    opacity: 0; /* Make the icon transparent by default */
    transition: visibility 0s, opacity 0.5s linear; /* Add a smooth transition effect */
}

.whatsapp-container.show {
    visibility: visible; /* Show the icon when the "show" class is added */
    opacity: 1; /* Make the icon fully visible */
}

.whatsapp-container1 {
    position: fixed;
    right: 11px;
    bottom: 67px;
    /* z-index: 1000; */
    visibility: hidden; /* Hide the icon by default */
    opacity: 0; /* Make the icon transparent by default */
    transition: visibility 0s, opacity 0.5s linear; /* Add a smooth transition effect */
}

.whatsapp-container1.show {
    visibility: visible; /* Show the icon when the "show" class is added */
    opacity: 1; /* Make the icon fully visible */
}