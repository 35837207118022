p {
    text-align: justify;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    margin-bottom: 15px;
  }

h3{
    font-weight: bold;
}  

.our-blogs{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 130px;
    border-radius: 12px;
    border: #fff solid 1px;
    background-color: rgb(202, 249, 249);
    text-align: center;
}
.other-blogs {
    width: 100%;
    transition: transform 0.3s;
  }
  
  .other-blogs:hover {
    transform: scale(0.9);
    transition: transform 0.3s ease; 
  }

  .scroll-to-top-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    display: none;
  }
  
  .scroll-to-top-btn.visible {
    display: block;
  }
  
  .scroll-to-top-btn span {
    font-size: 20px;
  }
  
