.industries-page-title {
  font-weight: bold;
  font-size: 55px;
  font-family: RedHatDisplayBold, Helvetica, Arial, sans-serif;
  background: linear-gradient(90deg, #000, #30256d, #30256d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 15px;
}

.boxes {
  flex: 0 0 auto;
  height: 180px;
  width: 180px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgb(222, 221, 221);
  margin-right: 10px;
  align-items: center;
  padding: 30px;
  justify-content: center;
  text-align: center;
  transition: background-color 2s ease,
}

.boxes:hover {
  background-color: #30256d
}


.box-img {
  height: 50px;
  width: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 35px;
}

.box-text {
  text-align: center;
}

.box-title {
  font-size: 50px;
  font-weight: bold;
  background: linear-gradient(90deg, #000, #30256d, #30256d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button {
  height: 45px;
  width: 170px;
  border: #000 solid;
  border-radius: 10px;
  background-color: #000;
  transition: background-color 0.3s ease,
}

.button:hover {
  background-color: #30256d;
  border: #30256d;
}

.overlap-text {
  color: #fff;
}

.seo-text {
  color: #fff;
}

.overlap-image {
  position: relative;
  height: 100%;
  width: 100%;
}


@media only screen and (max-width: 625px) {
  .service-responsive {
    margin-right: 0px;
  }

  .industries-page-title {
    font-size: 35px;
  }

  .box-title {
    font-size: 35px;
  }

  .overlap-text {
    position: none;
    margin-top: 0px;
    margin-left: 0;
    color: #000;
    font-size: 10px;
  }

  .all-text {
    color: #fff;
  }

  .overlap-image {
    position: none;
    height: 100%;
    width: 100%;
  }
}

.btn-5 {
  color: #fff;
  border: 0 solid;
  width: 30%;
  border-radius: 10px;
  padding: 0 1em 1em;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
  outline: 1px solid;
  outline-color: rgba(255, 255, 255, .5);
  outline-offset: 0px;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  text-decoration: none;
  display: flex;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
}

.btn-5:hover {
  color: #fff;
  border: 1px solid;
  box-shadow: inset 0 0 20px #000, 0 0 20px rgba(255, 255, 255, .2);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #feffff;
}


.req-btn {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #30256d;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
  box-sizing: border-box;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1.2px;
  overflow: hidden;
  cursor: pointer;
}

.req-btn:hover {
  opacity: .95;
}

.req-btn .animation {
  border-radius: 100%;
  animation: ripple 0.6s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
  }
}

.hide-web {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .hide-web {
    display: none;
  }
}

.service-space {
  width: 100%;
  /* height: 550px; */
  height: 100%;
  background-color: #fff;
  background-image: linear-gradient(32deg, #fff 30px, transparent);
  background-size: 60px 60px;
  background-position: -5px -5px;
  transition: background-color 1s, background-image 1s;
}

.service-space:hover {
  background-color: #5b0fb1;
  background-image: linear-gradient(32deg, #330765 30px, transparent);
}

.service-image {
  filter: grayscale(100%);
  width: 100%;
  height: auto;
  transition: filter 1s;
}

.service-space:hover .service-image {
  filter: grayscale(0%);

}

.service-text {
  text-align: end;
  margin-top: 20px;
  padding: 20px;
  font-weight: bold;
  font-size: 23px;
  transition: color 1s;
}

.service-space:hover .service-text {
  color: #fff;
}

.circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #330765;
}

.service-space {
  --c: #1095c1;
  line-height: 1.2em;
  background:
    conic-gradient(from -135deg at 100% 50%, var(--c) 90deg, #0000 0) 0 var(--p, 0%),
    conic-gradient(from -135deg at 1.2em 50%, #0000 90deg, var(--c) 0) 100% var(--p, 0%);
  background-size: var(--s, 0%) 200%;
  background-repeat: no-repeat;
  transition: .4s ease-in, background-position 0s;
}

.service-space:hover {
  --p: 100%;
  --s: calc(50% + .61em);
  color: #fff;
}

.service-newBox {
  width: 25vw;
  height: 90vh;
}

.row-service {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media only screen and (max-width: 625px) {
  .service-newBox {
    width: 100%;
    height: 500px;
    background: linear-gradient(135deg, #8c79f9, #30256d);
    margin-bottom: 10px;
  }

  .service-text-parent{
    display: flex;
    align-items: center;
    float: right;
  }

  .service-text {
  display: inline-block;
   color: #fff;
  }

  .service-image {
    filter: grayscale(0%) brightness(70%);
  }
}

@media only screen and (min-width: 626px) and (max-width: 1024px) {
  .service-newBox {
    width: 25%;
    background-color: #30256d;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1025px) {
  .service-newBox {
    width: 25%;
  }
}
