.about-text {
    font-family: 'Manrope ExtraBold';
    font-size: 40px;
    font-weight: bold;
    background: linear-gradient(90deg, #000, #30256d, #30256d);
    -webkit-background-clip: text; /* For older WebKit browsers */
    background-clip: text; /* Standard property */
    -webkit-text-fill-color: transparent;
    color: transparent;
}

.Whytaw-text {
    font-family: 'Manrope ExtraBold';
    font-size: 40px;
    font-weight: bold;
    background: linear-gradient(90deg, #000, #30256d, #30256d);
    -webkit-background-clip: text; /* For older WebKit browsers */
    background-clip: text; /* Standard property */
    -webkit-text-fill-color: transparent;
    color: transparent;
}

.know-text {
    font-family: 'Manrope ExtraBold';
    font-size: 10vh;
    font-weight: bold;
    background: linear-gradient(90deg, #000, #30256d, #30256d);
    -webkit-background-clip: text; /* For older WebKit browsers */
    background-clip: text; /* Standard property */
    -webkit-text-fill-color: linear-gradient(90deg, #000, #30256d, #30256d);
}


.age-text {
    font-family: 'Manrope ExtraBold';
    margin-top: 20px;
}


/* animated text starts */
.taw-animated-text {
    font-family: 'Russo One', sans-serif;
    position: absolute;
    width: 100%;
    height: 100%;
}

.taw-animated-text text {
    font-family: 'Russo One', sans-serif;
    text-transform: uppercase;
    animation: stroke 2s infinite alternate;
    stroke-width: 2;
    stroke: #30256d;
    font-size: 140px;
    font-weight: bold;
}

@keyframes stroke {
    0% {
        fill: rgba(72, 138, 20, 0);
        stroke: #30256d;
        stroke-dashoffset: 25%;
        stroke-dasharray: 0 50%;
        stroke-width: 2;
    }

    70% {
        fill: rgba(72, 138, 20, 0);
        stroke: #30256d;
    }

    80% {
        fill: rgba(72, 138, 20, 0);
        stroke: #30256d;
        stroke-width: 3;
    }

    100% {
        fill: #d3d3de;
        stroke: #30256d;
        stroke-dashoffset: -25%;
        stroke-dasharray: 50% 0;
        stroke-width: 0;
    }
}

/* mobile view */
@media only screen and (max-width: 625px) {
    /* .know-text {
        font-size: 55px;
    } */

    .about-text {
        font-size: 35px;
    }
}

/* Responsive mobile view */
@media only screen and (max-width: 768px) {
    .know-text {
        font-size: 10vw; /* Adjusted font size */
    }

    .age-text {
        margin-top: 20px;
        font-size: 2.5vh;
    }
}