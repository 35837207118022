
.error-image {
  height: 85vh;
}

.title-text {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;

}

.error-txt, .next-txt {
  text-align: center;
  font-size: 1.3rem;
  white-space: pre-wrap;
  margin-bottom: 10px;
}

.home-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff; /* You can change the color to fit your design */
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.home-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}