

.slide-item {
    position: relative;
  }
  
  .slide-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .read-more{
    text-align: start;
    font-size: 35;
    color: black;
  }
  
  @media (max-width: 767px) {
   
    .slide-content {
      display: block; 
    }
    .read-more{
      text-align: center;
    }
  }
  
  .image-container img {
    max-width: 100%;
    height: 400px;
    margin-right: 150px;

  }
  

.slick-dots li button {
  background-color: transparent;
  border: 1px solid #30256d; 
}


.slick-dots li.slick-active button {
  background-color: #30256d;
}

.slick-dots li button {
  width: 15px; 
  height: 15px; 
  margin:  1px 2px;
  border-radius: 50%;
}

.slick-dots li.slick-active button {
  color: white;
  width: 15px; 
  height: 15px; 
  margin:  1px 2px;
  border-radius: 50%;
  border: 1px solid #30256d; 
}
