.grid-title {
    color: #262626;
    font-size: 1.5em;
    line-height: normal;
    font-weight: 700;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    text-align: center;
}

.small-desc {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5em;
    color: #452c2c;
}

.small-desc {
    font-size: 1em;
}

.go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 2em;
    height: 2em;
    overflow: hidden;
    top: 0;
    right: 0;
    background: linear-gradient(135deg, #30256d, #30256d);
    border-radius: 0 4px 0 32px;
}

.go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
}

.grid {
    display: block;
    position: relative;
    height: 450px;
    background-color: #f2f8f9;
    border-radius: 10px;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    font-family: Arial, Helvetica, sans-serif;
}

.grid:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background-color: #30256d;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.35s ease-out;
}

.grid:hover:before {
    transform: scale(35);
}

.grid:hover .small-desc {
    transition: all 0.5s ease-out;
    color: rgba(255, 255, 255, 0.8);
}

.grid:hover .grid-title {
    transition: all 0.5s ease-out;
    color: #ffffff;
}

.grid-grid-image-stat {
    width: 100%;
    margin-top: 73px;
    filter: grayscale(100%);
    transition: all 0.8s ease-out;
}

.grid-grid-image-design {
    width: 100%;
    margin-top: 97px;
    filter: grayscale(100%);
    transition: all 0.8s ease-out;

}

.grid-grid-image-tech {
    width: 100%;
    margin-top: 50px;
    filter: grayscale(100%);
    transition: all 0.8s ease-out;
}

.grid:hover .grid-grid-image-stat,
.grid:hover .grid-grid-image-design,
.grid:hover .grid-grid-image-tech {
    filter: grayscale(0%);
}

@media only screen and (max-width: 625px) {

    .grid-grid-image-stat,
    .grid-grid-image-design,
    .grid-grid-image-tech {
        filter: grayscale(0%);
    }
}
