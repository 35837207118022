.service-title {
    -webkit-text-stroke: 1.5px #30256d;
    -webkit-text-fill-color: transparent;
    font-size: 45px;
    font-weight: bold;
}

.service-title-1 {
    -webkit-text-stroke: 1.5px #000;
    -webkit-text-fill-color: transparent;
    font-size: 40px;
    font-weight: bold;
}

.Create-Text {
    font-size: 23px;
    font-family: RedHatDisplayBold, Helvetica, Arial, sans-serif;
    font-weight: bold;
    background: linear-gradient(70deg, #000, #30256d, #30256d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

.tittle-img {
    max-width: auto;
    height: auto
}

.underline-custom {
    padding: 30px 0 8px;
    position: relative;
}

.underline-custom::before {
    content: "";
    position: absolute;
    left: 72%;
    bottom: 16px;
    width: 378px;
    height: 16px;
    transform: skew(-12deg) translateX(-50%);
    background: #000;
    z-index: -1;
}

.underline-custom-1 {
    padding: 30px 0 8px;
    position: relative;
}

.underline-custom-1::before {
    content: "";
    position: absolute;
    left: 76%;
    bottom: 10px;
    width: 380px;
    height: 16px;
    transform: skew(-12deg) translateX(-50%);
    background: #30256d;
    z-index: -1;
}

.service-heading {
    font-size: 35px;
    font-weight: bold;
    font-family: Times New Roman, Times, serif;
}


.service-description {
    font-size: 27px;
    font-weight: bold;
    font-family: Times New Roman, Times, serif;
    filter: drop-shadow(-15px 6px 1.5px #30256d)
}

.service-description-1 {
    font-size: 25px;
    font-weight: bolder;
    font-family: Times New Roman, Times, serif;
    filter: drop-shadow(-15px 6px 1.5px #30256d)
}

.service-description-2 {
    font-size: 30px;
    font-weight: bolder;
    font-family: Times New Roman, Times, serif;
    filter: drop-shadow(-15px 6px 1.5px #30256d)
}

.service-description-3 {
    font-size: 18px;
    font-weight: 600;
    font-family: Times New Roman, Times, serif;
}

@media screen and (max-width: 625px) {
    .underline-custom::before {
        content: "";
        position: absolute;
        left: 72%;
        bottom: 16px;
        width: 378px;
        height: 16px;
        transform: skew(-12deg) translateX(-50%);
        background: #000;
        z-index: -1;
    }

}



/* neww css */

.service-details-title {
    color: #102753;
    font-weight: 700;
}

.slider {
    overflow: hidden;
}

.slider-container {
    display: flex;
    animation: slideAnimation 30s linear infinite;
}

.slide {
    flex: 0 0 350px;
    height: 350px;
    width: 350px;
    margin-bottom: 30px;
    padding-left: 10px;
}

@keyframes slideAnimation {

    0%,
    100% {
        transform: translateX(0%);
    }

    50% {
        transform: translateX(-600px);
    }
}



.influ-reel {
    font-weight: 800;
    font-size: 80px;
    margin-left: 30px;
    margin-bottom: 60px;
    color: grey;
    line-height: 95px;
}

.influ-reels {
    font-weight: 800;
    font-size: 80px;
    margin-top: 60px;
    margin-left: 30px;
    margin-bottom: 20px;
    color: grey;
    line-height: 95px;
}
  
.req-btn {
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #30256d;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1.2px;
    overflow: hidden;
    cursor: pointer;
  }

.faq_hed {
    text-align: center;
    font-size: 40px;
    margin-top: 80px;
    margin-bottom: 40px;
    font-weight: 700;
    color: #393939;
    padding-bottom: 20px;
}

.li-text {
    margin-bottom: 20px;
}

.req-btn {
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #30256d;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1.2px;
    overflow: hidden;
    cursor: pointer;
  }
  
/* accordion */
:root {
    --accordion-min-height: 56px;
    --accordion-max-height: 100px;
}

details {
    width: 95%;
    margin: 0 auto;
    margin-bottom: .5rem;
    box-shadow: 0 .1rem 1rem -.5rem rgba(0, 0, 0, .4);
    border-radius: 4px;
    overflow: hidden;
    animation-name: accordion-close;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    transform-origin: top;
}

details[open] {
    animation-name: accordion-master;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    transform-origin: top;
}

@keyframes accordion-master {
    from {
        max-height: var(--accordion-min-height);
    }

    to {
        max-height: var(--accordion-max-height);
    }
}

@keyframes accordion-close {
    from {
        min-height: var(--accordion-max-height);
    }

    to {
        min-height: var(--accordion-min-height);
    }
}

summary {
    padding: 1.5rem;
    display: block;
    background: #ffffff;
    position: relative;
    cursor: pointer;
}

summary:before {
    content: '';
    background-image: url(https://raw.githubusercontent.com/microsoft/fluentui-system-icons/master/assets/Chevron%20Right/SVG/ic_fluent_chevron_right_48_filled.svg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    height: 24px;
    width: 24px;
    top: 1rem;
    right: 1rem;
    transform: rotate(90deg);
    transition: .25s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
details[open]>summary:before {
    transform: rotate(270deg);
}


details summary::-webkit-details-marker {
    display: none;
}

details>ul {
    padding-bottom: 1rem;
    margin-bottom: 0;
    opacity: 0;
    transition: 0.3s;
    height: 0;
    background: #ffffff;
    margin-top: 0;
    padding-top: 1rem;
    animation-name: accordion-content-close;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
}

details[open]>ul {
    opacity: 1;
    height: auto;
    animation-name: accordion;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    transform-origin: top;
}

@keyframes accordion {
    from {
        transform: scaleY(0);
        opacity: 0;
    }

    to {
        transform: scaleY(1);
        opacity: 1;
    }
}

@keyframes accordion-content-close {
    from {
        margin-top: 0;
    }

    to {
        margin-top: -300px;
    }
}

details[open]>ul>li {
    color: #333333;
    opacity: 0;
    animation-name: accordion-content;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
}

@keyframes accordion-content {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media only screen and (max-width: 767px) {
  
    .influ-reel {
        font-size: 40px;
        margin-bottom: 0px;
    }
    
    .influ-reels {
        font-size: 40px;
        margin-top: 0px;
    }
    .req-btn {
        height: 70px;
        width : 200px;

    }
    .service-details-title {
        margin-top: 50px;
    }
}
