

@keyframes slideLeftToRight {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.image-animation.slide-left-to-right {
  animation-name: slideLeftToRight;
  animation-duration: 2s; 
  animation-fill-mode: both;
  animation-timing-function: ease;
}


.slide-in-animation {
  animation: slideIn 2s forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%); 
    opacity: 0; 
  }
  to {
    transform: translateX(0); 
    opacity: 1; 
  }
}


@keyframes slideRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.text-animation.slide-right {
  animation-name: slideRight;
  animation-duration: 2s; 
  animation-fill-mode: both;
  animation-timing-function: ease;
}



@keyframes slideLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.text-animation.slide-left {
  animation-name: slideLeft;
  animation-duration: 2s; 
  animation-fill-mode: both;
  animation-timing-function: ease;
}



@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.text-animation.slide-up {
  animation-name: slideUp;
  animation-duration: 2s; 
  animation-fill-mode: both;
  animation-timing-function: ease; 
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
    filter: blur(10px);
  }
  to {
    opacity: 1;
    transform: scale(1);
    filter: blur(0);
  }
}



.text-animation.fade-in {
  animation-name: slideUp;
  animation-duration: 2s; 
  animation: fadeIn 2.5s ease-in-out;
}


@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.text-animation.slide-down {
  animation-name: slideDown;
  animation-duration: 2s; 
  animation-fill-mode: both;
  animation-timing-function: ease; 
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotating-image {
  animation: rotate 20s linear infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.5); 
    filter: blur(10px); 
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1); 
    filter: blur(0); 
  }
}

.fade-in-animation {
  animation: fadeIn 1.5s ease-in-out; 
}


@keyframes unfoldMat {
  0% {
    transform: scaleX(0);
    transform-origin: right;
    opacity: 0;
  }
  25% {
    transform: scaleX(0.75);
    opacity: 0.25;
  }
  50% {
    transform: scaleX(1.25);
    opacity: 0.5;
  }
  75% {
    transform: scaleX(1.1);
    opacity: 0.75;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}

.unfold-animation {
  animation: unfoldMat 3s cubic-bezier(0.4, 0, 0.2, 1);
}


