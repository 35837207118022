.explorbtn {
  font-size: 25px;
  font-weight: bold;
  text-decoration: none;
  margin-right: 15px;
  color: #30256da2;
}

.welove-text {
  font-family: 'Manrope', sans-serif;
  font-size: 18px;
  color: #000;
  text-align: justify;
}

.route-text {
  font-size: 20px;
  color: grey;
  text-decoration: none;
}

.digital-text {
  position: absolute;
  height: 40px;
  width: 115px;
  left: 0;
  margin-top: 70px;
  margin-left: 90px;
  border-radius: 5px;
  background-color: #d2d1d1;
  text-align: center;
  line-height: 40px;
  font-size: 19px;
  transition: background-color 0.1s;
}

.apply-now-btn {
  height: 40px;
  width: 115px;
  background-color: #fff;
  border-color: #30256d;
  text-align: center;
  line-height: 30px;
  border-radius: 8px;
  transition: background-color 0.1s;
}

.apply-now-btn:hover {
  color: #fff;
  border-color: #30256d;
  background-color: #30256d;
  box-shadow: 5px 5px 10px #f3f9c0;
  border-radius: 8px;
}

.content-box {
  height: 550px;
  border-radius: 15px;
  background-color: #30256d;
  text-align: center;
}

.content-box-text {
  color: #fff;
  font-size: 28px;
  text-align: center;
  justify-content: center;
}

.custom-file-upload {
  width: 100%;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  border-bottom: 1px solid #000;
}

.animated-image {
  width: 100%;
  opacity: 0;
  animation: fadeInOut 5s infinite alternate;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes textAnimation1 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.animated-arrow-btn {
  animation: textAnimation1 1s infinite;
}

@keyframes textAnimation2 {
  0% {
    transform: scale(2);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(2);
  }
}

.animated-coffee-text {
  animation: textAnimation2 3s infinite;
}

.mail-text {
  text-decoration: none;
  color: #30256d;
  transition: color 0.3s;
}

.mail-text:hover,
.mail-text:focus {
  color: #000;
}



.career-heading {
  text-align: start;

  h1 {
    color: #fff;
    font-size: 45px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: "Josefin Sans", sans-serif;
    background: linear-gradient(to right, #270054 10%, #8688c2 50%, #30256d 60%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 1.5s linear infinite;
    display: inline-block;
  }
}

.start-heading {
  text-align: start;

  h1 {
    color: #fff;
    font-size: 45px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: "Josefin Sans", sans-serif;
    background: linear-gradient(to right, #270054 10%, #8688c2 50%, #30256d 60%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 1.5s linear infinite;
    display: inline-block;
  }
}


@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.career-sub-heading {
  text-align: start;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  font-weight: 700;
  font-family: "Josefin Sans", sans-serif;
  background: linear-gradient(to right, #270054 10%, #8688c2 50%, #30256d 60%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 1.5s linear infinite;
  display: inline-block;
}

.career-box {
  padding-top: 100px;
  height: 500px;
  border-radius: 15px;
  background-color: #30256d;
  text-align: center;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.At-Taw-text {
  font-size: 55px;
  font-family: RedHatDisplayBold, Helvetica, Arial, sans-serif;
  font-weight: bold;
  margin-top: -25px;
  text-align: left;
  background: linear-gradient(90deg, #000, #30256d, #30256d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 625px) {
  .start-heading {
    margin-left: -8px;
    margin-top: -15px;
  }

  .At-Taw-text {
    font-size: 38px;
    margin-top: -45px;
    margin-bottom: -55px;
  }


  .content-box-text {
    color: #fff;
    font-size: 25px;
    text-align: center;
  }
  .career-box {
    margin-left: 10px;
    margin-right: 10px;
    height: 370px;
    border-radius: 15px;
    background-color: #30256d;
    text-align: center;
  }
}

/* new card */