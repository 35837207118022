/* Modal styles */
.modal {
  display: flex;
  justify-content: flex-end; /* Align to the right */
  align-items: flex-end; /* Align to the bottom */
  position: fixed;
  bottom: 0;
  right: 0;
  width: 30%; /* Adjust width as needed */
  max-width: 400px; /* Limit maximum width */
  height: 60%; /* Adjust height as needed */
  max-height: 600px; /* Limit maximum height */
  background-color: rgba(255, 255, 255, 0.5); /* White background with opacity */
  overflow: auto;
}

.modal-content {
  background-color: #fff; /* White background */
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  overflow: auto;
}

/* Close button styles */
.button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 1.5em;
  height: 1.5em;
  border: none;
  background: rgba(180, 83, 107, 0.11);
  border-radius: 50%;
  transition: background 0.5s;
}

.X {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1em;
  height: 1px;
  background-color: rgb(0, 0, 0); /* Black color */
  transform: translateX(-50%) rotate(45deg);
}

.Y {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1em;
  height: 1px;
  background-color: #000; /* Black color */
  transform: translateX(-50%) rotate(-45deg);
}

/* Input styles */
.input {
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 2px solid transparent;
  outline: none;
  border-bottom: 1px solid #000; /* Black bottom border */
  caret-color: #3f3f3f;
  background-color: transparent;
  padding: 5px;
  transition: .5s linear;
  font-family: monospace;
  letter-spacing: 1px;
  color: #000; /* Black text color */
}

.input:focus {
  border: 2px solid #000; /* Black border on focus */
  border-radius: 3px;
  caret-color: #000; /* Black caret color */
  color: #000; /* Black text color */
  box-shadow: 4px 4px 10px #070707;
}

.input:focus::placeholder {
  color: #000; /* Black placeholder color */
}

/* Dropdown styles */
.modal-content select {
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  padding: 8px;
  height: 40px;
  width: 100%;
  color: #000; /* Black text color */
}

/* Styling for option elements within the select dropdown */
.modal-content select option {
  color: #3f3f3f;
}
