@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400&display=swap');

h1.stroke,
h2.stroke {
  -webkit-text-stroke: 1px #000;
  -webkit-text-fill-color: transparent;
  font-size: 150px;
}

.stroke-margin {
  margin-left: 30px;
}

.specialty-text {
  -webkit-text-stroke: 1px #000;
  -webkit-text-fill-color: transparent;
  font-size: 110px;
}

.bannertitle h1,
.bannertitle h2 {
  font-size: 110px;
  font-family: RedHatDisplayBold, RedHatDisplayBold, Helvetica, Arial, sans-serif;
}

.taw-title {
  font-size: 150px;
  line-height: 195px;
  font-family: RedHatDisplayBold, Helvetica, Arial, sans-serif;
  font-weight: bold;
  background: linear-gradient(90deg, #000, #30256d, #30256d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.agency-text {
  font-weight: bold;
}

.brewText-stroke {
  font-size: 25px;
  font-weight: bold;
}

.responsive-text {
  margin-top: 150px;
}

.but-text {
  font-family: RedHatDisplayBold;
  color: #000;
  font-size: 65px;
  font-weight: bold;
}

.redefine-text {
  font-size: 45px;
  font-family: RedHatDisplayBold, Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(90deg, #000, #30256d, #30256d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.paragraph-text {
  font-family: 'Manrope', sans-serif;
  font-size: 18px;
  color: #000;
  text-align: justify;
}

.discover-text {
  font-family: RedHatDisplayBold;
  color: #000;
  font-size: 50px;
  font-weight: bold;
  text-decoration: none !important;
}

.creating-text {
  font-size: 45px;
  font-weight: bold;
  text-align: center;
}

.bullet-random {
  margin-top: 100px;
  padding-left: 100px;
}

.custom-arrow-bullet-point {
  list-style-type: none;
  padding: 0;
}

.custom-arrow-bullet-point li::before {
  content: '➟';
  color: #000;
  font-size: 1.5em;
  margin-right: 0.5em;
}


.mobile_down_arrow {
  position: absolute;
  margin-top: 100px;
  transform: translateX(-50%);
  animation: moveUpDown 1s infinite alternate;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

.zoom-overlay {
  position: absolute;
  top: 10%;
  left: 82%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #30256d;
  opacity: 0.6;
  animation: zoomAnimation 2s infinite alternate;
}

.grid-view {
  height: 500px;
  background-color: #e3e0e0;
  border-radius: 10px;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.grid-text {
  font-size: 17px;
  color: #000;
  font-weight: 400;
}

.grid-view:hover {
  color: #fff;
  background-color: #30256d;
}

@keyframes zoomAnimation {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.5);
  }
}

.video-content {
  position: absolute;
  top: 10%;
  right: 10%;
  left: 2%;
  padding: 30px;
}

/* Vendor Prefixes */
.video-main {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
}


.video-banner {
  width: 100%;
  height: 100%;
}

.submit-button {
  font-family: inherit;
  height: 150px;
  width: 150px;
  font-size: 23px;
  background: #30256d;
  border: 3px solid #150281;
  color: white;
  padding: 0.7em 1em;
  padding-left: 0.9em;
  display: flex;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
}


.submit-button span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.submit-button svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.submit-button:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.submit-button:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.submit-button:hover span {
  transform: translateX(5em);
}

.submit-button:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}


.SeeAll-button {
  font-family: inherit;
  height: 150px;
  width: 150px;
  font-size: 21px;
  background: #30256d;
  border: 3px solid #150281;
  color: white;
  padding: 0.7em 1em;
  padding-left: 0.9em;
  display: flex;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
}


.SeeAll-button span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.SeeAll-button svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.SeeAll-button:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.SeeAll-button:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.SeeAll-button:hover span {
  transform: translateX(5em);
}

.SeeAll-button:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

.Newstyle-text {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: -20px;
  background: linear-gradient(90deg, #000, #30256d, #30256d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.framing-text {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: -5px;
  background: linear-gradient(90deg, #000, #30256d, #30256d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.the-agency {
  font-weight: bold;
  font-family: "Manrope", sans-serif;
  font-size: 65px;
  background: linear-gradient(90deg, #000, #30256d, #30256d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Division-title {
  font-size: 150px;
  font-family: RedHatDisplayBold, Helvetica, Arial, sans-serif;
  font-weight: bold;
  background: linear-gradient(90deg, #000, #30256d, #30256d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Lies-title {
  font-size: 110px;
  font-family: RedHatDisplayBold, Helvetica, Arial, sans-serif;
  font-weight: bold;
  background: linear-gradient(90deg, #000, #30256d, #30256d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-stretch: unset;
}

.Color-Text {
  font-family: RedHatDisplayBold, Helvetica, Arial, sans-serif;
  font-weight: bold;
  background: linear-gradient(70deg, #000, #30256d, #30256d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 625px) {
  .stroke-margin {
    margin-left: 0px;
  }

  .Newstyle-text {
    font-size: 40px;
  }

  .framing-text {
    font-size: 40px;
  }

  .video-main {
    width: 100%;
    height: 70%;
    
  }

  .Lies-title {
    font-size: 50px;
    font-family: RedHatDisplayBold, Helvetica, Arial, sans-serif;
    font-weight: bold;
    background: linear-gradient(90deg, #000, #30256d, #30256d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-stretch: unset;
  }

  .Division-title {
    font-size: 75px;
    margin-top: 25px;
    font-family: RedHatDisplayBold, Helvetica, Arial, sans-serif;
    font-weight: bold;
    background: linear-gradient(90deg, #000, #30256d, #30256d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-stretch: unset;
  }



  .taw-title {
    font-size: 75px;
    line-height: 195px;
    font-family: RedHatDisplayBold, Helvetica, Arial, sans-serif;
    font-weight: bold;
    background: linear-gradient(90deg, #000, #30256d, #30256d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-stretch: unset;
    margin-bottom: -40px;
  }
}

.image-text-effect {
  background-image: url('../../assets/giphy.webp');
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 150px;
  font-weight: bold;
  margin-top: -30px;
}

.brand-title {
  font-size: 115px;
  line-height: 195px;
  font-family: RedHatDisplayBold, Helvetica, Arial, sans-serif;
  font-weight: bold;
  background: linear-gradient(90deg, #000, #30256d, #30256d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.exp-title {
  font-size: 115px;
  line-height: 195px;
  margin-top: -104px;
  font-family: RedHatDisplayBold, Helvetica, Arial, sans-serif;
  font-weight: bold;
  background: linear-gradient(90deg, #000, #30256d, #30256d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-sub-heading {
  font-size: 25px;
  font-weight: 600;
  font-family: "Josefin Sans", sans-serif;
  background: linear-gradient(to right, #270054 10%, #8688c2 50%, #30256d 60%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 1.5s linear infinite;
  display: inline-block;
  text-align: center;
  margin-top: 20px;
}


/* mobile view */

@media (max-width: 625px) {
  .image-text-effect {
    font-size: 75px;
  }

  .brand-title {
    font-size: 65px;
    margin-top: -62px;
  }

  .exp-title {
    font-size: 65px;
    margin-top: -150px;
    margin-bottom: -40px;
  }

  .Lies-title {
    margin-top: -10px;
    margin-bottom: -25px;

  }

  .specialty-text {
    -webkit-text-stroke: 1px #000;
    -webkit-text-fill-color: transparent;
    font-size: 45px;
  }

  .creating-text {
    font-size: 45px;
    font-family: RedHatDisplayBold, Helvetica, Arial, sans-serif;
    font-weight: bold;
    text-align: center;
    background: linear-gradient(90deg, #000, #30256d, #30256d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .redefine-text {
    text-align: start;
    /* font-size: 2.5rem; */
    font-size: 25px;
    margin-top: 15px;
  }

  .mobile_down_arrow {
    display: none;
  }

  .h1.stroke,
  h2.stroke,
  .bannertitle h1,
  .bannertitle h2 {
    font-size: 50px;
    line-height: 60px;
  }

  .but-text {
    font-size: 35px;
  }

  .responsive-text {
    margin-top: 5px;
  }

  .specialty-title {
    margin-left: 0px;
  }

  .home-sub-heading {
    text-align: start;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: -15px;
  }
}
