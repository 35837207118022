
.header {
  position: sticky;
  top: 0;
  z-index: 10000; 
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.header.scrolled {
  background-color: white;
}

.other-page {
  color: black !important;
}

.scrolled .nav-link {
  color: black !important;
}

.nav-link {
  text-decoration: none;
  font-weight: bold;
  margin-left: 30px;
  font-size: 20px;
  color: #fff;
}

.header.scrolled .nav-link {
  color: black;
}

.logo-img {
  height: 43px;
  width: 170px;
  border-radius: 5%;
}
.nav-link:hover {
  color: #30256d;
}
.navbar-toggler {
  background-color: #fff;
}

.navbar-nav {
  margin-left: auto;
}

.mobile-nav-left {
  margin-left: 0;
}

.navcolor{
  color: white;
}


@media (max-width: 767px) {
  .navbar-nav {
    margin-left: 0;
  }

  .nav-link {
    text-decoration: none;
    font-weight: bold;
    margin-left: -29px;
    font-size: 20px;
    color: #fff;
  }
  
}



