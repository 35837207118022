.modal-body {
    width: 100%;
    height: 350px;
    padding-top: 100px;
    text-align: center;
    background: #03045e;
    border-radius: 0px 90px 0px 90px;
    --gap: 5em;
    --line: 1px;
    --color: rgba(255, 255, 255, 0.2);

    background-image: linear-gradient(-90deg,
            transparent calc(var(--gap) - var(--line)),
            var(--color) calc(var(--gap) - var(--line) + 1px),
            var(--color) var(--gap)),
        linear-gradient(0deg,
            transparent calc(var(--gap) - var(--line)),
            var(--color) calc(var(--gap) - var(--line) + 1px),
            var(--color) var(--gap));
    background-size: var(--gap) var(--gap);
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.loading-text {
    text-align: center;
    margin-top: 15px;
    font-size: 25px;
    font-weight: 500;
    color: #6767fa;
    font-size: 25px;
    animation: fadeInOut 1.5s infinite;
}

.respose-text {
    text-align: center;
    margin-top: 15px;
    font-size: 25px;
    font-weight: 500;
    color: #6767fa;
    font-size: 25px;
}


.close-btn {
    border-radius: 50%;
    padding: 0.5em;
    width: 30px;
    height: 30px;
    border: 2px solid #fff;
    color: #fff;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #03045e;
}

.close-btn::before {
    content: " ";
    position: absolute;
    display: block;
    background-color: #fff;
    width: 2px;
    left: 12px;
    top: 5px;
    bottom: 5px;
    transform: rotate(45deg);
}

.close-btn::after {
    content: " ";
    position: absolute;
    display: block;
    background-color: blue;
    height: 2px;
    top: 12px;
    left: 5px;
    right: 5px;
    transform: rotate(45deg);
}

.flex {
    min-height: 60pt;
}

@keyframes loading {
    0% {
        width: 50pt;
        height: 50pt;
        margin-top: 0;
    }

    25% {
        height: 4pt;
        margin-top: 23pt;
    }

    50% {
        width: 4pt;
    }

    75% {
        width: 50pt;
    }

    100% {
        width: 50pt;
        height: 50pt;
        margin-top: 0;
    }
}

.loader {
    position: relative;
    width: 50pt;
    height: 50pt;
    border-radius: 100%;
    border: #6767fa 4pt solid;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    animation: loading 1s infinite;
}

.loader-done {
    position: relative;
    width: 50pt;
    height: 50pt;
    border-radius: 100%;
    border: #6767fa 4pt solid;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
}

.loader-done::after {
    content: "🗸";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #6767fa;
    font-size: 32px;
    font-weight: bolder;
    transform-origin: bottom right;
    animation: drawTick 0.5s forwards;
}

