.india-text {
  font-family: Times New Roman, Times, serif;
  color: #000;
  font-weight: bold;
  font-size: 25px;
}

.contact-text {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  text-decoration: none;
  font-family: "Manrope Regular";
}

.contact-text:hover {
  text-decoration: underline;
  color: #555;
}

.address {
  font-family: "Manrope Regular";
  font-size: 18px;
}

.view-map-text {
  font-size: 25px;
  font-weight: bold;
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none
}

.form-field {
  height: 50px;
  width: 100%;
  background: #fff;
  border: none;
  border: none;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid #000;
}

.custom-select {
  position: relative;
  font-family: Arial, sans-serif;
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: 1px solid #000;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  margin-top: 10px;
  background: url(../../assets/images/select-arrrow.webp) center no-repeat #fff;
  background-position-x: 97%;

}


.custom-select::after {
  content: '\25BC';
  font-size: 12px;
  color: #999;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}


.custom-select option {
  background-color: white;
  color: #333;
}

.submit {
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: url(../../assets/images/arrow-more2.webp) 50% 50px no-repeat #fff;


  transition: background-color 0.3s ease, box-shadow 0.5s ease, transform 0.3s ease;
  border: 2px solid #000;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  padding-top: 18px;
}


.submit:hover {
  background-color: #30256d;
  transform: scale(1.1);
  border: 0.5px;
}

.error-tooltip {
  position: absolute;
  top: -5px;
  right: -10%;
  transform: translateX(-50%);
  padding: 3px;
  background-color: #30256d;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
}

.arrow {
  position: absolute;
  width: 0;
  height: 0;
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  border-bottom-color: #30256d;
  border-width: 8px;
  margin-left: -8px;
}

.full-screen-image {
  height: 100vh;
  overflow: hidden;
}

/* mobile view */
@media only screen and (max-width: 600px) {
  .full-screen-image {
    height: 100%;
    overflow: hidden;
  }
}



/* new button */
